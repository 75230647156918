import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import styled from 'styled-components';
import { FACEBOOK_URL, LINKEDIN_URL } from '../configuration';
import Logo from '../images/logo-color.png';
import NoSupportImage from '../images/szlaczki.png';
import { GlobalStyle, rem, theme } from '../theme';

const Box = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  height: 100vh;
  min-height: 100vh;
`;

const InfoSection = styled.div`
  width: 50%;
  height: auto;
  background-color: ${theme.colors.brandColor[900]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  background-image: url(${NoSupportImage});
  background-color: ${theme.colors.brandColor[900]};
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
`;

const InfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${theme.colors.white};

  & h1 {
    font-size: ${rem(11)};
    border-bottom: 5px solid ${theme.colors.brandColor[500]};
    padding-bottom: 32px;
    margin-bottom: 32px;
    margin-top: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 48px;
    width: 500px;
    font-size: ${rem(6)};

    & b {
      color: ${theme.colors.brandColor[300]};
    }
  }
`;

const LogoContainer = styled.div`
  background-image: url(${Logo});
  background-size: cover;
  background-repeat: no-repeat;
  height: 120px;
  width: 97px;
  margin-bottom: 48px;
`;

const AddressRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 48px;

  & div:first-child {
    margin-right: 32px;
  }
`;

const AddressPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: ${rem(1)};
  line-height: 20px;

  & h3 {
    color: ${theme.colors.brandColor[500]};
    text-transform: uppercase;
    margin-top: 0;
    font-size: ${rem(2)};
  }
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  & > a {
    color: ${theme.colors.brandColor[500]};
    font-size: ${rem(6)};
    & > svg {
      margin-right: 16px;
      &:hover {
        color: ${theme.colors.brandColor[600]};
      }
    }
  }
`;

const NoSupportPage = () => {
  const data = useStaticQuery(graphql`
    {
      dataJson(type: { eq: "contact" }) {
        id
        company
        phones
        emails
        address {
          street
          zip
          city
          voivodship
          country
        }
      }
    }
  `);

  return (
    <Box>
      <GlobalStyle />
      <InfoSection>
        <InfoDetails>
          <LogoContainer />
          <h1>Whoops...</h1>
          <p>
            It seems that you are using <b>Internet Explorer</b> which we do not
            support. Please use <b>other modern browser</b> to see full version
            of our website.
          </p>
          <AddressRow>
            <AddressPart>
              <h3>Phone & email</h3>
              {data.dataJson.phones.map((el: string) => {
                return <div key={el}>{el}</div>;
              })}
              {data.dataJson.emails.map((el: string) => {
                return <div key={el}>{el}</div>;
              })}
            </AddressPart>
            <AddressPart>
              <h3>Address</h3>
              <div>{data.dataJson.address.street}</div>
              <div>
                {data.dataJson.address.zip} {data.dataJson.address.city}
              </div>
              <div>
                {data.dataJson.address.voivodship},{' '}
                {data.dataJson.address.country}
              </div>
            </AddressPart>
          </AddressRow>
          <SocialMedia>
            <a href={LINKEDIN_URL} target="_blank">
              <FaLinkedin></FaLinkedin>
            </a>
            <a href={FACEBOOK_URL} target="_blank">
              <FaFacebookSquare></FaFacebookSquare>
            </a>
          </SocialMedia>
        </InfoDetails>
      </InfoSection>
      <ImageContainer></ImageContainer>
    </Box>
  );
};

export default NoSupportPage;
